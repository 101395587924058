import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.step)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.classes)
      }, [
        _cache[0] || (_cache[0] = _createElementVNode("p", { class: "label" }, "Next step:", -1)),
        _createElementVNode("p", null, _toDisplayString(_ctx.step.name ? _ctx.step.name : _ctx.step.type), 1)
      ], 2))
    : _createCommentVNode("", true)
}