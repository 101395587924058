import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "inner"
}
const _hoisted_2 = { "aria-label": "Type" }
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value"]
const _hoisted_5 = {
  key: 0,
  "aria-label": "Name"
}
const _hoisted_6 = ["value"]
const _hoisted_7 = { "aria-label": "Minutes" }
const _hoisted_8 = ["value"]
const _hoisted_9 = { "aria-label": "Seconds" }
const _hoisted_10 = ["value"]
const _hoisted_11 = {
  key: 1,
  class: "inner"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = {
  key: 1,
  class: "time"
}
const _hoisted_14 = {
  key: 2,
  class: "noTimeLimitInfo"
}
const _hoisted_15 = ["aria-label"]
const _hoisted_16 = {
  key: 2,
  class: "buttons"
}
const _hoisted_17 = ["disabled"]
const _hoisted_18 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_check = _resolveComponent("icon-check")!
  const _component_icon_edit = _resolveComponent("icon-edit")!
  const _component_icon = _resolveComponent("icon")!
  const _component_icon_arrow_up = _resolveComponent("icon-arrow-up")!
  const _component_icon_arrow_down = _resolveComponent("icon-arrow-down")!
  const _component_icon_copy = _resolveComponent("icon-copy")!
  const _component_icon_trash = _resolveComponent("icon-trash")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(_ctx.classes)
  }, [
    (_ctx.isEditing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("label", _hoisted_2, [
            _createElementVNode("select", {
              class: "typeSelect",
              value: _ctx.step.type,
              name: "type",
              onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleUpdate($event)))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stepTypes, (type) => {
                return (_openBlock(), _createElementBlock("option", {
                  value: type.value,
                  key: type.value
                }, _toDisplayString(type.label), 9, _hoisted_4))
              }), 128))
            ], 40, _hoisted_3)
          ]),
          (_ctx.step.type === _ctx.StepType.Exercise)
            ? (_openBlock(), _createElementBlock("label", _hoisted_5, [
                _createElementVNode("input", {
                  class: "nameInput",
                  value: _ctx.step.name,
                  name: "stepName",
                  onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleUpdate($event)))
                }, null, 40, _hoisted_6)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            _createElementVNode("label", _hoisted_7, [
              _createElementVNode("input", {
                type: "number",
                class: "timeInput",
                min: "0",
                max: "60",
                step: "1",
                value: _ctx.minutes,
                name: "minutes",
                onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleUpdate($event)))
              }, null, 40, _hoisted_8),
              _cache[9] || (_cache[9] = _createTextVNode(" : "))
            ]),
            _createElementVNode("label", _hoisted_9, [
              _createElementVNode("input", {
                type: "number",
                class: "timeInput",
                min: "0",
                max: "60",
                step: "5",
                value: _ctx.seconds,
                name: "seconds",
                onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleUpdate($event)))
              }, null, 40, _hoisted_10)
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("p", null, _toDisplayString(_ctx.step.type), 1),
          (_ctx.step.name)
            ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_ctx.step.name), 1))
            : _createCommentVNode("", true),
          (_ctx.step.duration > 0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.minutes) + ":" + _toDisplayString(_ctx.seconds), 1))
            : (_openBlock(), _createElementBlock("p", _hoisted_14, "No timer"))
        ])),
    _createElementVNode("button", {
      class: "editButton",
      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleStepEdit && _ctx.handleStepEdit(...args))),
      "aria-label": _ctx.isEditing ? 'Stop editing step' : 'Edit step'
    }, [
      _createVNode(_component_icon, {
        width: "24",
        height: "24"
      }, {
        default: _withCtx(() => [
          (_ctx.isEditing)
            ? (_openBlock(), _createBlock(_component_icon_check, { key: 0 }))
            : (_openBlock(), _createBlock(_component_icon_edit, { key: 1 })),
          _cache[10] || (_cache[10] = _createTextVNode("} "))
        ]),
        _: 1
      })
    ], 8, _hoisted_15),
    (_ctx.isEditing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createElementVNode("button", {
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleClickOnMoveUpButton && _ctx.handleClickOnMoveUpButton(...args))),
            disabled: _ctx.isFirst,
            "aria-label": "Move step upward"
          }, [
            _createVNode(_component_icon, {
              width: "28",
              height: "28"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_icon_arrow_up)
              ]),
              _: 1
            })
          ], 8, _hoisted_17),
          _createElementVNode("button", {
            onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleClickOnMoveDownButton && _ctx.handleClickOnMoveDownButton(...args))),
            disabled: _ctx.isLast,
            "aria-label": "Move step downward"
          }, [
            _createVNode(_component_icon, {
              width: "28",
              height: "28"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_icon_arrow_down)
              ]),
              _: 1
            })
          ], 8, _hoisted_18),
          _createElementVNode("button", {
            onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.handleStepClone && _ctx.handleStepClone(...args))),
            "aria-label": "Clone step"
          }, [
            _createVNode(_component_icon, {
              width: "28",
              height: "28"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_icon_copy)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("button", {
            onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.handleStepDelete && _ctx.handleStepDelete(...args))),
            "aria-label": "Delete step"
          }, [
            _createVNode(_component_icon, {
              width: "28",
              height: "28"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_icon_trash)
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}