import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "workoutManager" }
const _hoisted_2 = {
  key: 1,
  class: "selector"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { key: 2 }
const _hoisted_5 = ["value"]
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { key: 4 }
const _hoisted_8 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_caret_down = _resolveComponent("icon-caret-down")!
  const _component_icon = _resolveComponent("icon")!
  const _component_icon_add_circle = _resolveComponent("icon-add-circle")!
  const _component_icon_check = _resolveComponent("icon-check")!
  const _component_rounded_button = _resolveComponent("rounded-button")!
  const _component_icon_close = _resolveComponent("icon-close")!
  const _component_icon_edit = _resolveComponent("icon-edit")!
  const _component_icon_trash = _resolveComponent("icon-trash")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (!_ctx.isRenaming)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "switchButton",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSelect && _ctx.toggleSelect(...args))),
          "aria-label": "Select another workout"
        }, [
          _createVNode(_component_icon, {
            width: "32",
            height: "32",
            class: _normalizeClass(['icon', _ctx.isSelecting ? 'reverse' : ''])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon_caret_down)
            ]),
            _: 1
          }, 8, ["class"]),
          _createElementVNode("span", null, _toDisplayString(_ctx.name), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isSelecting)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workouts, (w) => {
            return (_openBlock(), _createElementBlock("li", {
              key: w.id,
              onClick: ($event: any) => (_ctx.selectWorkout(w.id))
            }, _toDisplayString(w.name), 9, _hoisted_3))
          }), 128)),
          _createElementVNode("li", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.createWorkout && _ctx.createWorkout(...args)))
          }, [
            _createVNode(_component_icon, {
              width: "32",
              height: "32",
              class: "iconBeforeLabel"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_icon_add_circle)
              ]),
              _: 1
            }),
            _cache[3] || (_cache[3] = _createElementVNode("span", null, "New workout", -1))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isRenaming)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("input", {
            value: _ctx.name,
            class: "nameInput",
            onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleNameUpdate($event)))
          }, null, 40, _hoisted_5)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isRenaming)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_rounded_button, {
            class: "actionButton confirmColor",
            onClick: _ctx.renameWorkout
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon, {
                width: "24",
                height: "24",
                class: "iconBeforeLabel"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_icon_check)
                ]),
                _: 1
              }),
              _cache[4] || (_cache[4] = _createElementVNode("span", null, "Rename", -1))
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_rounded_button, {
            class: "actionButton cancelColor",
            onClick: _ctx.cancelRenaming
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon, {
                width: "24",
                height: "24",
                class: "iconBeforeLabel"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_icon_close)
                ]),
                _: 1
              }),
              _cache[5] || (_cache[5] = _createElementVNode("span", null, "Cancel", -1))
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isDeleting)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _cache[8] || (_cache[8] = _createElementVNode("p", { class: "question" }, "Are you sure buddy?", -1)),
          _createVNode(_component_rounded_button, {
            class: "actionButton confirmColor",
            onClick: _ctx.deleteWorkout
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon, {
                width: "24",
                height: "24",
                class: "iconBeforeLabel"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_icon_check)
                ]),
                _: 1
              }),
              _cache[6] || (_cache[6] = _createElementVNode("span", null, "Delete", -1))
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_rounded_button, {
            class: "actionButton cancelColor",
            onClick: _ctx.cancelDeleting
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon, {
                width: "24",
                height: "24",
                class: "iconBeforeLabel"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_icon_close)
                ]),
                _: 1
              }),
              _cache[7] || (_cache[7] = _createElementVNode("span", null, "Cancel", -1))
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isSelecting && !_ctx.isRenaming && !_ctx.isDeleting)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_rounded_button, {
            class: "actionButton",
            onClick: _ctx.toggleRename
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon, {
                width: "24",
                height: "24",
                class: "iconBeforeLabel"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_icon_edit)
                ]),
                _: 1
              }),
              _cache[9] || (_cache[9] = _createElementVNode("span", null, "Rename", -1))
            ]),
            _: 1
          }, 8, ["onClick"]),
          (_ctx.workouts.length > 1)
            ? (_openBlock(), _createBlock(_component_rounded_button, {
                key: 0,
                class: "actionButton",
                onClick: _ctx.toggleDelete
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_icon, {
                    width: "24",
                    height: "24",
                    class: "iconBeforeLabel"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_icon_trash)
                    ]),
                    _: 1
                  }),
                  _cache[10] || (_cache[10] = _createElementVNode("span", null, "Delete", -1))
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}