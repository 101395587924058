import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: $props.width,
    height: $props.height,
    viewBox: "0 0 16 16"
  }, [
    _createElementVNode("g", { fill: $props.iconColor }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 8, _hoisted_2)
  ], 8, _hoisted_1))
}