import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock, withKeys as _withKeys } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "block expanded"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = {
  key: 1,
  class: "block expanded"
}
const _hoisted_7 = {
  key: 2,
  class: "block"
}
const _hoisted_8 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_program_runner_timer = _resolveComponent("program-runner-timer")!
  const _component_rounded_button = _resolveComponent("rounded-button")!
  const _component_icon_round_play = _resolveComponent("icon-round-play")!
  const _component_icon = _resolveComponent("icon")!
  const _component_program_runner_next_step = _resolveComponent("program-runner-next-step")!
  const _component_icon_previous = _resolveComponent("icon-previous")!
  const _component_icon_pause = _resolveComponent("icon-pause")!
  const _component_icon_play = _resolveComponent("icon-play")!
  const _component_icon_next = _resolveComponent("icon-next")!
  const _component_program_runner_progress = _resolveComponent("program-runner-progress")!
  const _component_icon_stop = _resolveComponent("icon-stop")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(['programRunner', _ctx.themeClass]),
    onKeyup: [
      _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.handleClickOnPrevious && _ctx.handleClickOnPrevious(...args)), ["left"])),
      _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.handleClickOnNext && _ctx.handleClickOnNext(...args)), ["right"])),
      _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.handleClickOnStartPause && _ctx.handleClickOnStartPause(...args)), ["space"]))
    ],
    onTouchstart: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleTouchStart && _ctx.handleTouchStart(...args))),
    onTouchend: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleTouchEnd && _ctx.handleTouchEnd(...args))),
    tabindex: "0"
  }, [
    (_ctx.isStarted)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[6] || (_cache[6] = _createElementVNode("label", { class: "label" }, "Total", -1)),
          _createVNode(_component_program_runner_timer, {
            time: _ctx.program.totalTime,
            isSmall: "",
            hideCentiseconds: ""
          }, null, 8, ["time"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(['block', 'main', _ctx.themeClass])
    }, [
      (_ctx.isStarted)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.title), 1),
            _createVNode(_component_program_runner_timer, { time: _ctx.time }, null, 8, ["time"])
          ]))
        : (_ctx.isFinished)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _cache[8] || (_cache[8] = _createElementVNode("p", { class: "title" }, "Well done!", -1)),
              _cache[9] || (_cache[9] = _createElementVNode("p", { class: "title" }, "You did it in:", -1)),
              _createVNode(_component_program_runner_timer, {
                time: _ctx.program.totalTime
              }, null, 8, ["time"]),
              _createVNode(_component_rounded_button, {
                class: "endButton",
                onClick: _ctx.handleClickOnStop
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("Okay!")
                ])),
                _: 1
              }, 8, ["onClick"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("button", {
                class: "bigPlayButton",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClickOnStartPause && _ctx.handleClickOnStartPause(...args))),
                "aria-label": "Start"
              }, [
                _createVNode(_component_icon, {
                  width: "24",
                  height: "24"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_icon_round_play)
                  ]),
                  _: 1
                }),
                _cache[10] || (_cache[10] = _createElementVNode("div", null, "Let's do it!", -1))
              ])
            ]))
    ], 2),
    (_ctx.isStarted)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_program_runner_next_step, {
            step: _ctx.nextStep,
            isVisible: _ctx.isNextStepVisible
          }, null, 8, ["step", "isVisible"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isStarted)
      ? (_openBlock(), _createElementBlock("footer", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_rounded_button, {
              onClick: _ctx.handleClickOnPrevious,
              "aria-label": "Previous step"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_icon, {
                  width: "24",
                  height: "24"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_icon_previous)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_rounded_button, {
              onClick: _ctx.handleClickOnStartPause,
              "aria-label": _ctx.isRunning ? 'Pause' : 'Start'
            }, {
              default: _withCtx(() => [
                (_ctx.isRunning)
                  ? (_openBlock(), _createBlock(_component_icon, {
                      key: 0,
                      width: "24",
                      height: "24"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_icon_pause)
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_icon, {
                      key: 1,
                      width: "24",
                      height: "24"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_icon_play)
                      ]),
                      _: 1
                    }))
              ]),
              _: 1
            }, 8, ["onClick", "aria-label"]),
            _createVNode(_component_rounded_button, {
              onClick: _ctx.handleClickOnNext,
              "aria-label": "Next step"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_icon, {
                  width: "24",
                  height: "24"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_icon_next)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _createVNode(_component_program_runner_progress, {
            steps: _ctx.workout.steps,
            currentStep: _ctx.currentStep
          }, null, 8, ["steps", "currentStep"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isPaused)
      ? (_openBlock(), _createBlock(_component_rounded_button, {
          key: 3,
          class: "stopButton",
          onClick: _ctx.handleClickOnStop,
          "aria-label": "Stop workout"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_icon, {
              width: "24",
              height: "24"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_icon_stop)
              ]),
              _: 1
            }),
            _cache[11] || (_cache[11] = _createElementVNode("span", null, "Stop", -1))
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ], 34))
}