import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "programBuilder" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "list" }
const _hoisted_4 = ["onDrop"]
const _hoisted_5 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_program_builder_step = _resolveComponent("program-builder-step")!
  const _component_icon_add_circle = _resolveComponent("icon-add-circle")!
  const _component_icon = _resolveComponent("icon")!
  const _component_rounded_button = _resolveComponent("rounded-button")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, "My step list (" + _toDisplayString(_ctx.workout.steps.length) + ")", 1),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workout.steps, (step, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: step.id
        }, [
          _createElementVNode("div", {
            class: "dropzone",
            onDragenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dragEnter($event))),
            onDragleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dragLeave($event))),
            onDrop: ($event: any) => (_ctx.dragDrop($event, index)),
            onDragover: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
          }, null, 40, _hoisted_4),
          _createVNode(_component_program_builder_step, {
            step: step,
            isFirst: index === 0,
            isLast: index === _ctx.workout.steps.length - 1,
            isEditing: step.id === _ctx.editingStep,
            onStartEditing: _ctx.handleStepStartEditing,
            onUpdate: _ctx.handleStepUpdate,
            onClone: _ctx.handleStepClone,
            onDelete: _ctx.handleStepDelete,
            onUp: _ctx.handleStepUp,
            onDown: _ctx.handleStepDown,
            draggable: "true",
            onDragstart: ($event: any) => (_ctx.dragStart($event, index))
          }, null, 8, ["step", "isFirst", "isLast", "isEditing", "onStartEditing", "onUpdate", "onClone", "onDelete", "onUp", "onDown", "onDragstart"])
        ]))
      }), 128))
    ]),
    _createElementVNode("footer", _hoisted_5, [
      _createVNode(_component_rounded_button, {
        class: "addButton",
        onClick: _ctx.handleClickOnAddButton
      }, {
        default: _withCtx(() => [
          _createVNode(_component_icon, {
            width: "24",
            height: "24",
            class: "iconBeforeLabel"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon_add_circle)
            ]),
            _: 1
          }),
          _cache[3] || (_cache[3] = _createElementVNode("span", null, "Add one more", -1))
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}